<template>
  <div class="icon__remain__ticket">
    <icon-ticket :color='iconColor' />
    <p>残り: {{ count }}</p>
  </div>
</template>
<script>
import IconTicket from '@/components/atoms/icon/IconTicket.vue';

export default {
  components: {
    IconTicket,
  },
  props: {
    iconColor: String,
    count: Number,
  },
};
</script>

<style lang="scss">
.icon__remain__ticket {
  background-color: $color-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  border-radius: 3px;
  width: 115px;
  p {
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 16px;
  }
}
</style>
