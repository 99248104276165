<template>
  <div class='question'>
    <layout-header />
    <main class='question__main'>
      <section class='question__main__modal'>
        <div class='question__main__modal__text'>
          <h3>質問チケット使用ページ</h3>
          <p>
            このページでは質問チケットを使用することができます。
            <br>
            「指名なし」か「指名あり」によって使用するチケットが異なります。
            <br>
            予約時指定した方を選択してください。
          </p>
        </div>
        <div class='question__main__modal__toggle'>
          <button-toggle @emitClickToggle='changeToggleSelect($event)' :select='toggleInfo.select'
            :nameList='toggleInfo.nameList' />
          <p v-if="ticketUseGroups[toggleInfo.select].message">
            {{ ticketUseGroups[toggleInfo.select].message }}
          </p>
        </div>
        <div v-if="existQuestionTicketCount" class='question__main__modal__count'>
          <div v-for="ticket in ticketUseGroups[toggleInfo.select].tickets" :key="ticket.id">
            <h3 class="question__main__modal__count__title">{{ ticket.ticketName }}</h3>
            <div class="question__main__modal__count__main">
              <icon-remain-ticket
                :iconColor="ticket.color"
                :count="setTicketCount(ticket.ticketId)"
              />
              <p class="question__main__modal__count__main__arrow">▶︎</p>
              <icon-remain-ticket
                :iconColor="ticket.color"
                :count="setTicketCount(ticket.ticketId) - ticket.cost"
              />
            </div>
          </div>
          <button-check-box
            class='question__main__modal__checkbox'
            text='「チケットを使用する」ボタンのロックを解除する'
            :isActive='isActive.agreeCheckBox'
            @emit='isActive.agreeCheckBox = !isActive.agreeCheckBox '
          />
          <button-normal @emitClick='execUseTicket()' :loading='isLoading.useTicket'
            :disabled='!isActive.agreeCheckBox'>
            <p>チケットを使用する</p>
          </button-normal>
        </div>
        <div v-else class='question__main__modal__buy'>
          <div class='question__main__modal__buy__text'>
            <p>チケットが不足しています。<br>下ボタンからチケット購入を行なって下さい。</p>
          </div>
          <button-normal>
            <router-link :to='{
              name: "SettingTickets",
              params: { ticketName: "question" }
            }'>
              チケットを購入する
            </router-link>
          </button-normal>
        </div>
      </section>
    </main>
    <layout-footer />
  </div>
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import ButtonCheckBox from '@/components/atoms/button/ButtonCheckBox.vue';
import ButtonToggle from '@/components/atoms/button/ButtonToggle.vue';
import IconRemainTicket from '@/components/molecules/other/IconRemainTicket.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
    ButtonCheckBox,
    ButtonToggle,
    IconRemainTicket,
  },
  data() {
    return {
      ticketUseGroups: [
        {
          groupName: 'question',
          message: '質問チケットを１枚使用します。',
          tickets: [
            {
              ticketId: 1,
              ticketName: '質問チケット',
              color: 'blue',
              cost: 1,
            },
          ],
        },
        {
          groupName: 'question-appoint',
          message: '質問チケット・指名チケットをそれぞれ１枚ずつ使用します。',
          tickets: [
            {
              ticketId: 1,
              ticketName: '質問チケット',
              color: 'blue',
              cost: 1,
            },
            {
              ticketId: 2,
              ticketName: '指名チケット',
              color: 'red',
              cost: 1,
            },
          ],
        },
      ],
      isLoading: {
        useTicket: false,
      },
      isActive: {
        agreeCheckBox: false,
      },
      toggleInfo: {
        select: 0,
        nameList: [
          '指名なし',
          '指名あり',
        ],
      },
      showUseTicket: false,
    };
  },
  created() {
    this.setToggleInfoSelect();
  },
  mounted() {
    this.execLoadTicketInfo();
  },
  computed: {
    existQuestionTicketCount() {
      const costTicket = this.ticketUseGroups[this.toggleInfo.select].tickets;
      const hasTicket = costTicket.map((v) => {
        const myTicketCount = this.$store.state.ticket
          .find((w) => w.id === v.ticketId).user_tickets_count;
        return myTicketCount - v.cost >= 0;
      });
      return hasTicket.indexOf(false) === -1;
    },
    setTicketCount() {
      return (ticketId) => {
        const target = this.getTicketInfoById(ticketId);
        return target.user_tickets_count;
      };
    },
    setTicketName() {
      return (ticketId) => {
        const target = this.getTicketInfoById(ticketId);
        return target.name;
      };
    },
  },
  watch: {
    $route() {
      this.setToggleInfoSelect();
    },
  },
  methods: {
    async execUseTicket() {
      this.isLoading.useTicket = true;
      const uploadDatas = this.ticketUseGroups[this.toggleInfo.select].tickets
        .map((v) => ({
          ticket_id: Number(v.ticketId),
          count: v.cost,
        }));

      // リクエストを１回で行う場合
      // [{ticket_id: 1, count: 1}, {ticket_id: 2, count: 1}]の状態でりクエストを送る
      // const result = await this.$store.dispatch('useTicket', uploadDatas);
      // if (result === 401) {
      //   this.$router.push({ name: 'Top' });
      // } else if (!result) {
      //   this.$router.push({ name: 'Error' });
      // } else {
      //   this.$router.push({ name: 'TicketComplete' });
      // }

      const result = await Promise.all(uploadDatas
        .map(async (uploadData) => this.$store.dispatch('useTicket', uploadData)));

      if (result.indexOf(401) !== -1) {
        this.$router.push({ name: 'Top' });
      } else if (result.indexOf(false) !== -1) {
        this.$router.push({ name: 'Error' });
      } else {
        this.$router.push({ name: 'TicketComplete' });
      }
    },
    async execLoadTicketInfo() {
      const result = await this.$store.dispatch('loadTicketInfo');
      if (result === 401) {
        this.$router.push({ name: 'Top' });
      } else if (!result) {
        this.$router.push({ name: 'Error' });
      }
    },
    changeToggleSelect(index) {
      const { groupName } = this.ticketUseGroups[index];
      this.$router.push({ name: 'TicketQuestion', params: { ticketName: groupName } });
    },
    setToggleInfoSelect() {
      this.toggleInfo.select = this.ticketUseGroups
        .findIndex(({ groupName }) => groupName === this.$route.params.ticketName);
    },
    getTicketInfoById(ticketId) {
      return this.$store.state.ticket.filter((v) => v.id === Number(ticketId))[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.question__main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.question__main__modal {
  background-color: $color-white;
  width: 600px;
  text-align: center;
  padding: 30px 30px 35px;
  margin-bottom: 50px;
}

.question__main__modal__checkbox {
  margin: 50px 0 15px;
}

.question__main__modal__text {
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    margin: 10px 0 20px;
  }
  a {
    text-decoration: none;
    color: $color-black;
  }
  a:visited {
    color: $color-black;
  }
}

.question__main__modal__buy {
  padding-top: 20px;
  &__text {
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
      margin: 10px 0 20px;
    }
    a {
      text-decoration: none;
      color: $color-black;
    }
    a:visited {
      color: $color-black;
    }
  }
}

.question__main__modal__toggle {
  ul {
    width: 50%;
    margin: 0 auto;
  }
  p {
    margin-top: 20px;
    font-size: 14px;
  }
}

.question__main__modal__count {
  > div {
    margin-top: 40px;
  }
}

.question__main__modal__count__title {
  margin-bottom: 20px;
}

.question__main__modal__count__main {
  display: flex;
  justify-content: center;
  align-items: center;

  &__arrow {
    color: $color-dark-gray;
    padding: 0 20px;
  }
}
</style>
