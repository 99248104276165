<template>
  <div :class='addClass'>
    <i class='fas fa-credit-card' />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'blue',
      validator(value) {
        return ['blue', 'red'].indexOf(value) !== -1;
      },
    },
  },
  computed: {
    addClass() {
      return `${this.color} icon-ticket`;
    },
  },
};
</script>

<style lang="scss">
.icon-ticket {
  font-size: 20px;
  display: flex;
  align-items: center;
}
.blue {
  color: $color-blue;
}

.red {
  color: $color-red;
}
</style>
